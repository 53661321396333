"use strict";

import Select from '../utilities/select';
import Css from '../utilities/css';

class ToggleClass{
    constructor( triggerEl, onCallback, offCallback ){
        this.triggerEl = triggerEl || null;
        this.triggerToggleTargetId = null;
        this.triggerElToggleClass = 'toggled__';
        this.triggerElementEvent = 'click';
        this.targetEl = null;
        this.targetElToggleClass = '';
        this.onCallback = typeof(onCallback) === 'function' ? onCallback : function(){};
        this.offCallback = typeof(offCallback) === 'function' ? offCallback : function(){};
        if(this.triggerEl !== null){
            this.triggerToggleTargetId = this.triggerEl.getAttribute('data-toggle');
            this.triggerElementEvent = this.triggerEl.getAttribute('data-toggle-event') || this.triggerElementEvent;
            this.targetElToggleClass = this.triggerEl.getAttribute('data-toggle-class');
            this.targetEl = Select.one('[data-toggle-id="'+this.triggerToggleTargetId+'"]');
        }
        if(this.targetEl !== null){
            this.listenForEvent();
        }
    }
    listenForEvent(){
        this.triggerEl.addEventListener(this.triggerElementEvent, function(e){
            e.preventDefault();
            this.toggle();
        }.bind(this), false);
    }
    toggle(){
        if(Css.hasClass(this.targetEl, this.targetElToggleClass)) {
            Css.removeClass(this.targetEl, this.targetElToggleClass);
            Css.removeClass(this.triggerEl, this.triggerElToggleClass + this.targetElToggleClass);
            this.offCallback();
        }
        else {
            Css.addClass(this.targetEl, this.targetElToggleClass);
            Css.addClass(this.triggerEl, this.triggerElToggleClass + this.targetElToggleClass);
            this.onCallback();
        }
    }
}

export default ToggleClass;