"use strict";

var Css = require('./css');

export default {
    outerHeight: function(el){ return el.offsetHeight; },
    outerHeightPlusMargins: function (el) {
        var height = this.outerHeight(el);
        height += parseInt( Css.getStyle(el, 'margin-top') ) + parseInt( Css.getStyle(el, 'margin-bottom') );
        return height;
    },
    outerWidth: function(el){ return el.offsetWidth; },
    outerWidthPlusMargins: function (el) {
        var width = this.outerWidth(el);
        width += parseInt( Css.getStyle(el, 'margin-left') ) + parseInt( Css.getStyle(el, 'margin-right') );
        return width;
    }
};
