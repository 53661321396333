"use strict";

import Select from '../utilities/select';
import Css from '../utilities/css';
import {parentUntilAttribute} from '../utilities/traverse';

export default function(){
    // tax forms toggles
    const toggleTaxFormElements = Select.all('[data-toggle-form]');
    const toggleTaxFormClass = 'tax-forms__list__item--show-details';
    if( !!toggleTaxFormElements && toggleTaxFormElements.length ){
        toggleTaxFormElements.forEach(function(el) {
            el.addEventListener('click', function(e){
                e.preventDefault();
                try {
                    const taxFormElement = parentUntilAttribute(e.target, 'data-toggle-form');
                    const taxFormElementParent = taxFormElement.parentNode;
                    Css.hasClass(taxFormElementParent, toggleTaxFormClass) ? Css.removeClass(taxFormElementParent, toggleTaxFormClass) : Css.addClass(taxFormElementParent, toggleTaxFormClass);
                }
                catch (e) {
                    console.log( e );

                }
            }, false);
        });
    }
}