"use strict";

import '../sass/app.scss';

import Select from './utilities/select';
import Css from './utilities/css';
import Swiper from 'swiper';
import Size from './utilities/size';
import Debounce from './utilities/debounce';
import {parentUntilAttribute} from './utilities/traverse';
import taxFormsToggle from './src/taxFormsToggle';
import helpFaqToggle from './src/helpFaqToggle';
import helpCategoriesToggle from './src/helpCategoriesToggle';
import ToggleClass from './src/toggle';
import {OffCanvas, settings as offCanvasSettings} from "./src/offCanvas";
import {getOffsetRect} from './utilities/position';
import scrollTo from 'scroll-to';
import countdown from 'countdown';

let headerMain = Select.one('[data-header-main]');
let heroMain = Select.one('[data-hero-main]');
let makeRoomForHeaderForThisElement = Select.one('[data-make-header-room]');

let adjustHeroMainHeight = function(){
    let headerMainHeight = parseInt( Size.outerHeight( headerMain ) );
    if( heroMain !== null ){
        let heroMainBottomPadding = parseInt( Css.getStyle( heroMain, 'paddingBottom' ) );
        Css.setStyle( heroMain, {paddingTop: (headerMainHeight + heroMainBottomPadding) + 'px'} );
    }
    if( makeRoomForHeaderForThisElement !== null ){
        Css.setStyle( makeRoomForHeaderForThisElement, {paddingTop: headerMainHeight * 1.5 + 'px'} );
    }
};


if( headerMain !== null && heroMain !== null || headerMain !== null && makeRoomForHeaderForThisElement !== null ){
    window.addEventListener('resize', Debounce(adjustHeroMainHeight(), 250), false);
    adjustHeroMainHeight();
}

let animate = Select.one('[data-animate]');
let animate1 = Select.one('[data-animate-1]');
let animate2 = Select.one('[data-animate-2]');
let animate3 = Select.one('[data-animate-3]');
let animate4 = Select.one('[data-animate-4]');
if( !!animate ){
    let timerId = setTimeout(function(){
      Css.addClass( animate, 'tada' );
      Css.addClass( animate, 'animated' );
    }, 2000 );
}
if( !!animate1 && !!animate2 && !!animate3 ){
    let timer2Id = setTimeout(function(){
        Css.addClass( animate1, 'slideInLeft' );
        Css.addClass( animate2, 'zoomIn' );
        Css.addClass( animate3, 'slideInRight' );
        Css.addClass( animate1, 'animated' );
        Css.addClass( animate2, 'animated' );
        Css.addClass( animate3, 'animated' );
    }, 500 );
}

if( !!animate4 ){
    let timer3Id = setTimeout(function(){
        Css.addClass( animate4, 'fadeIn' );
        Css.addClass( animate4, 'animated' );
    }, 5000 );
    let timer4Id = setTimeout(function(){
        Css.addClass( animate4, 'fadeOut' );
        Css.addClass( animate4, 'animated' );
    }, 10000 );
}

const heroSlides = Select.all('[data-swiper="hero"]');
if( !!heroSlides && heroSlides.length > 1  ){
    let myHeroSwiper = new Swiper('[data-swiper="hero"]', {
        loop: heroSlides.length > 1,
        speed: 1500,
        autoplay: {
            delay: 6000,
            disableOnInteraction: true,
        },
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}


let myTestimonialSwiper = new Swiper('[data-swiper="testimonials"]', {
    loop: true,
    speed: 1500,
    autoplay: {
        delay: 7500,
        disableOnInteraction: true,
    },
    slidesPerView: 1,
    spaceBetween: 32,
    grabCursor: true,
    breakpoints: {
        960: {
            slidesPerView: 2,
            spaceBetween: 32,
        },
    }
});

taxFormsToggle();
helpFaqToggle();
helpCategoriesToggle();

const toggleElements = Select.all('[data-toggle]');
if(toggleElements !== null){
    if(toggleElements.length){
        for(let i= 0, len=toggleElements.length; i < len; i++){
            new ToggleClass(toggleElements[i]);
        }
    }
}

const offCanvasElements = Select.all('['+ offCanvasSettings.wrapperAttribute +']');
if(offCanvasElements !== null && offCanvasElements.length){
    for(let i= 0, len = offCanvasElements.length; i < len; i++){
        new OffCanvas(offCanvasElements[i]).init();
    }
}

const scrollToTriggerElements = Select.all('[data-scroll-to]');
if( !!scrollToTriggerElements && scrollToTriggerElements.length ){
    scrollToTriggerElements.forEach(function(el) {
        el.addEventListener('click', function(e){
            e.preventDefault();
            try {
                const element = parentUntilAttribute(e.target, 'data-scroll-to');
                const scrollToId = element.getAttribute('data-scroll-to');
                const targetElement = Select.one('[data-scroll-to-id="'+ scrollToId +'"]');
                if( targetElement !== null ){
                    const targetElementPosition = getOffsetRect( targetElement );
                    scrollTo(0, (targetElementPosition.top - parseInt( Size.outerHeight( headerMain ) )));
                }
            }
            catch (e) {
                console.log( e );
            }
        }, false);
    });
}

const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
const halfHeight = viewPortHeight / 2;
const scrollToTopElement = Select.one('[data-scroll-to="top"]');

//countdowns
let countdownTimers = [];
const countdownAttributeName = 'data-countdown';
const countdownElements = Select.all('['+countdownAttributeName+']');
const evCountdownToString = function(ts){
    return '<div class="countdown__table">' +
        '<div class="countdown__row countdown__row__numbers">' +
        '<div class="countdown__cell countdown__cell__numbers">' + ts.days + '</div>' +
        '<div class="countdown__cell countdown__cell__numbers">' + ((ts.hours < 10 && ts.hours >= 0) ? ("0" + ts.hours) : ts.hours) + '</div>' +
        '<div class="countdown__cell countdown__cell__numbers">' + ((ts.minutes < 10 && ts.minutes >= 0) ? ("0" + ts.minutes) : ts.minutes ) + '</div>' +
        '<div class="countdown__cell countdown__cell__numbers countdown__cell__numbers--seconds">' + ((ts.seconds < 10 && ts.seconds >= 0) ? ("0" + ts.seconds) : ts.seconds) + '</div>' +
        '</div><div class="countdown__row countdown__row__description">' +
        '<div class="countdown__cell countdown__cell__description">Day' + ((ts.days > 1) ? 's' : '') + '</div>' +
        '<div class="countdown__cell countdown__cell__description">Hour' + ((ts.hours > 1) ? 's' : '') + '</div>' +
        '<div class="countdown__cell countdown__cell__description">Minutes</div>' +
        '<div class="countdown__cell countdown__cell__description">Seconds</div></div></div>';
};
const createTimer = function(id, el){
    let year = el.getAttribute(countdownAttributeName+'-year');
    let month = el.getAttribute(countdownAttributeName+'-month');
    let day = el.getAttribute(countdownAttributeName+'-day');
    return countdown( function(ts) {
        let evCountdownString = '';
        if(ts.value > 0){
            evCountdownString = evCountdownToString(ts);
        }
        else{
            evCountdownString = '<h4>Due!</h4>';
            if( !!countdownTimers[id] )
                clearInterval(countdownTimers[id]);
        }
        el.innerHTML = evCountdownString;
    }, new Date(year, month, day), countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS );
};
if( !!countdownElements && countdownElements.length ){
    for( let i=0, len=countdownElements.length; i<len ; i++ ){
        let id = countdownElements[i].getAttribute(countdownAttributeName);
        countdownTimers[id] = createTimer( id, countdownElements[i] );
    }
}
