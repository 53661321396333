"use strict";

import Select from '../utilities/select';
import Css from '../utilities/css';
import {parentUntilAttribute} from '../utilities/traverse';

export default function(){
    // faq toggles
    const toggleFaqElements = Select.all('[data-faq]');
    const toggleHelpFaqClass = 'help__category__faq--show-answer';
    if( !!toggleFaqElements && toggleFaqElements.length ){
        toggleFaqElements.forEach(function(el) {
            el.addEventListener('click', function(e){
                e.preventDefault();
                try {
                    const faqElement = parentUntilAttribute(e.target, 'data-faq');
                    const faqId = faqElement.getAttribute('data-faq');
                    const faqElementParent = parentUntilAttribute(faqElement, 'data-faq-id');
                    Css.hasClass(faqElementParent, toggleHelpFaqClass) ? Css.removeClass(faqElementParent, toggleHelpFaqClass) : Css.addClass(faqElementParent, toggleHelpFaqClass);
                }
                catch (e) {
                    console.log( e );
                }
            }, false);
        });
    }
}