"use strict";

import Select from '../utilities/select';
import Css from '../utilities/css';

const settings = {
        wrapperAttribute: "data-off-canvas",
        directionAttribute: "data-off-canvas-direction",
        contentContainerAttribute: 'data-off-canvas-container',
        menuAttribute: 'data-off-canvas-menu',
        toggleButtonsAttribute: 'data-off-canvas-toggle',
        closeButtonsAttribute: 'data-off-canvas-close',
        contentContainerOpenAttribute: 'data-off-canvas-open',
        openClass: 'off-canvas__container--open',
        closeActionClassForToggleButton: 'off-canvas__toggle-button__action--close-canvas'
    };

class OffCanvas{
    constructor(wrapperEl){
        this.wrapperEl = wrapperEl || null;
        if( this.wrapperEl !== null ) {
            this.offCanvasId = this.wrapperEl.getAttribute(settings.wrapperAttribute);
            this.offCanvasDirection = this.wrapperEl.getAttribute(settings.directionAttribute) || null;
            this.openClass = this.getOpenClassName(this.offCanvasDirection);
            this.contentContainerEl = Select.one('['+ settings.contentContainerAttribute +'="'+ this.offCanvasId +'"]', this.wrapperEl );
            this.contentContainerOverlay = document.createElement('a');
            Css.addClass(this.contentContainerOverlay, 'off-canvas__container__overlay');
            this.menuEl = Select.one('['+ settings.menuAttribute + '="'+ this.offCanvasId +'"]', this.wrapperEl );
            this.toggleButtons = Select.all('['+ settings.toggleButtonsAttribute + '="'+ this.offCanvasId +'"]');
            this.closeButtons = Select.all('['+ settings.closeButtonsAttribute + '="'+ this.offCanvasId +'"]');
        }
    }
    init(){
        if(this.toggleButtons !== null && this.toggleButtons.length){
            for(let i= 0, len=this.toggleButtons.length; i < len; i++){
                this.toggleButtons[i].addEventListener('click', function(e){
                    e.preventDefault();
                    this.toggle();
                }.bind(this), false);
            }
        }
        if(this.closeButtons !== null && this.closeButtons.length){
            for(let ii= 0, len2=this.closeButtons.length; ii < len2; ii++){
                this.closeButtons[ii].addEventListener('click', function(e){
                    e.preventDefault();
                    this.close();
                }.bind(this), false);
            }
        }
        this.contentContainerOverlay.addEventListener('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            this.close();
        }.bind(this),false);
    }
    open(){
        this.contentContainerEl.setAttribute(settings.contentContainerOpenAttribute,'');
        Css.addClass(this.contentContainerEl, this.openClass);
        this.forceRedraw(this.contentContainerEl);
        this.contentContainerEl.appendChild(this.contentContainerOverlay);
        for(let i= 0, len=this.toggleButtons.length; i < len; i++){
            Css.addClass(this.toggleButtons[i], settings.closeActionClassForToggleButton);
        }
    }
    close(){
        this.contentContainerEl.removeAttribute(settings.contentContainerOpenAttribute);
        Css.removeClass(this.contentContainerEl, this.openClass);
        for(let i= 0, len=this.toggleButtons.length; i < len; i++){
            Css.removeClass(this.toggleButtons[i], settings.closeActionClassForToggleButton);
        }
        this.removeContentContainerOverlay();
    }
    removeContentContainerOverlay(){
        if(this.contentContainerOverlay.parentNode !== null) {
            const destroy = function(){
                this.contentContainerOverlay.parentNode.removeChild(this.contentContainerOverlay);
            }.bind(this);
            let timeoutID = window.setTimeout(destroy, 150);
        }
    }
    forceRedraw(el){
        el.offsetHeight;
    }
    removeAnyFocusFromClick(){
        if (document.activeElement != document.body)
            document.activeElement.blur();
    }
    toggle(){
        if(this.contentContainerEl.hasAttribute(settings.contentContainerOpenAttribute)){
            this.close();
        }
        else{
            this.open();
        }
    }
    getOpenClassName(direction){
        return settings.openClass + (direction ? '--' + direction : '' );
    }
}

export {
    OffCanvas,
    settings
}
