"use strict";

import Select from '../utilities/select';
import Css from '../utilities/css';
import {parentUntilAttribute} from '../utilities/traverse';

export default function(){
    // help categories toggle
    const toggleHelpCategoriesElements = Select.all('[data-help-category]');
    const toggleHelpCategoriesClass = 'help__category--show-category';
    if( !!toggleHelpCategoriesElements && toggleHelpCategoriesElements.length ){
        toggleHelpCategoriesElements.forEach(function(el) {
            el.addEventListener('click', function(e){
                e.preventDefault();
                try {
                    const helpCatgoryElement = parentUntilAttribute(e.target, 'data-help-category');
                    const helpCategoryId = helpCatgoryElement.getAttribute('data-help-category');
                    const helpCategorySections = Select.all('[data-help-category-id]');
                    const targetHelpCategorySection = Select.one('[data-help-category-id="'+ helpCategoryId +'"]');
                    if( helpCategorySections !== null && helpCategorySections.length && targetHelpCategorySection !== null ){
                        helpCategorySections.forEach(function(categoryElement, index){
                            Css.removeClass( categoryElement, toggleHelpCategoriesClass);
                        });
                        Css.addClass(targetHelpCategorySection, toggleHelpCategoriesClass);
                    }
                }
                catch (e) {
                    console.log( e );
                }
            }, false);
        });
    }
}